/* Fonts
================================================== */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic);

@font-face {
    font-family: 'montserratultra_light';
    src: url('../fonts/montserrat-ultralight-webfont.eot');
    src: url('../fonts/montserrat-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         //url('../fonts/montserrat-ultralight-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-ultralight-webfont.woff') format('woff'),
         url('../fonts/montserrat-ultralight-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-ultralight-webfont.svg#montserratultra_light') format('svg');
    font-weight: normal;
    font-style: normal;

}