/* Footer
================================================== */

#ccc-icon {
	margin: 0px !important;
}

footer {
	width: 100%;
	float: left;
}

.footer-right {
	width: 100%;
	float: left;
	overflow: hidden;
	@include bp(xxlarge) {
		width: 35%;
	}
}

.footer-contact {
	width: 100%;
	min-height: 60%;
	float: left;
	padding: 15% 10%;
	background-color: $blue;
	h3,
	p,
	a {
		color: $white;
	}
	h3 {
		font-size: 1.5em;
	}
	p {
		font-size: 0.9em;
	}
	a {
		&:hover {
			p {
				color: $secondary-colour;
			}
		}
	}
	.small-button {
		float: left;
		clear: both;
		i {
			color: $secondary-colour;
		}
	}
	.phone-button {
		.icon,
		i {
			@include bp(smedium) {
				cursor: default;
			}
		}
	}
	.email-button {
		i {
			font-size: 1.5em;
		}
	}
}
.join-us,
.get-in-touch {
	width: 100%;
	float: left;
}
.join-us {
	margin-bottom: 120px;
}

.footer-main {
	width: 100%;
	min-height: 40%;
	float: left;
	padding: 10%;
	background-color: $dim-grey;
	ul {
		width: 100%;
		float: left;
		margin: 0 0 20px 0;
		padding: 5px;
		text-align: center;
		@include bp(xxlarge) {
			margin: 0 0 50px 0;
		}
		li {
			width: 36%;
			height: 75px;
			position: relative;
			margin: 0 5%;
			padding: 0 3px;
			list-style: none;
			@extend %inline-block;
			overflow: hidden;
			@include bp(smedium) {
				width: 24%;
				margin: 0;
			}
			@include bp(large) {
				width: 16%;
			}
			@include bp(xxlarge) {
				width: 24%;
			}
			@include bp(xxlarge) {
				width: 16%;
			}
		}
		img {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			width: auto;
			padding: 10px;
			@extend %va;
		}
	}
}
.footer-links,
.footer-details {
	text-align: center;
	a {
		color: $white;
		&:hover {
			color: $light-steel-blue;
		}
	}
}
.footer-links {
	margin-bottom: 30px;
	a {
		margin: 5px 30px;
		font-size: 0.9em;
		@include bp(xxlarge) {
			font-size: 1em;
		}
	}
}
.footer-details {
	font-size: 0.8em;
	p {
		color: $light-steel-blue;
	}
}
