/* Intro Section
================================================== */
.first-section {
	position: relative;
	width: 100%;
	padding: 20px;
	background-color: $midnight-blue;
	overflow: hidden;
	&.intro {
		padding: 20px 20px 200px 20px;
		@include gradient($midnight-blue, $teal);
		@include bp(xxlarge) {
			height: 1200px;
		}
	}
}

.intro-section {
	position: relative;
	z-index: 9;
	width: 100%;
	float: left;
	margin-top: 50px;
	@include bp(large) {
		float: none;
		//@extend %va;
		margin-top: 0;
	}
	.container {
		@include bp(xxlarge) {
			width: $sixteen-width;
			max-width: $sixteen-width;
		}
	}
}

.intro-text {
	width: 100%;
	margin-bottom: 40px;
	padding-left: 0;
	@include bp(smedium) {
		width: 75%;
		padding-left: 10px;
	}
	.title {
		font-size: 1.6em;
		font-family: $secondary-font;
		font-weight: bold;
		color: $secondary-colour;
		text-transform: uppercase;
		line-height: 130%;
		@include bp(large) {
			font-size: 2.4em;
		}
		@include bp(large) {
			font-size: 3em;
		}
		@include bp(xxlarge) {
			font-size: 3.6em;
		}
		@include bp(xxlarge) {
			font-size: 4.6em;
		}
	}
}

/* Mouse Scroll
================================================== */
.mouse-scroll {
	position: absolute;
	right: 0;
	bottom: 25px;
	left: 0;
	display: none;
	margin: auto;
	text-align: center;
	@include bp(large) {
		display: block;
	}
	.container {
		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: -10px;
			left: 0;
			z-index: 1;
			margin: auto;
			//width: 960px;
			height: 400px;
			background: url("${template-image-path}/mouse-shadow.png") no-repeat 50%
				50%;
			visibility: visible;
			@include bp(large) {
				display: block;
			}
		}
	}
	.mouse-icon {
		position: relative;
		.mouse-icon-arrow {
			position: absolute;
			top: 5px;
			right: 0;
			left: 0;
			margin: auto;
			-webkit-animation: mouseScroll 0.6s alternate infinite;
			animation: mouseScroll 0.6s alternate infinite;
		}
	}
}

@-webkit-keyframes mouseScroll {
	0% {
		-webkit-transform: translateY(-2px);
		transform: translateY(-2px);
	}
	100% {
		-webkit-transform: translateY(2px);
		transform: translateY(2px);
	}
}

@keyframes mouseScroll {
	0% {
		-webkit-transform: translateY(-2px);
		transform: translateY(-2px);
	}
	100% {
		-webkit-transform: translateY(2px);
		transform: translateY(2px);
	}
}
