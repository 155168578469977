/* Second Section
================================================== */
.second-section {
	width: 100%;
	float: left;
	padding: 10%;
	background: url("${template-image-path}second-section-bg.jpg") no-repeat 50%
		50%;
	@extend %cover;
	text-align: center;
	@include bp(xxlarge) {
		width: 70%;
	}
	@include bp(xxlarge) {
		width: 80%;
	}
	h1 {
		position: relative;
		margin-bottom: 40px;
		font-size: 1.5em;
		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: -15px;
			left: 0;
			width: 30%;
			height: 2px;
			margin: auto;
			background: linear-gradient(
				to right,
				rgba(0, 0, 0, 0) 0%,
				rgba(77, 91, 105, 1) 50%,
				rgba(0, 0, 0, 0) 100%
			);
		}
	}
	h2 {
		width: 100%;
		margin: 0 auto 40px auto;
		font-family: $secondary-font;
		font-size: 1.6em;
		font-weight: 400;
		line-height: 140%;
		@include bp(large) {
			font-size: 2.4em;
		}
		@include bp(xxlarge) {
			width: 70%;
			font-size: 3em;
		}
	}
}

.second-section-content {
	margin-bottom: 150px;
}

.key-services {
	ul {
		margin: 0 0 15px 0;
	}
	li {
		margin: 8px;
		list-style: none;
		font-size: 1.3em;
		@extend %inline-block;
	}
	a {
		color: $primary-colour;
		text-transform: uppercase;
		font-weight: 200;
		font-family: $tertiary-font;
		&:hover i {
			color: $primary-colour;
		}
		i {
			color: $secondary-colour;
			@include transition(color 400ms ease-in-out);
		}
	}
	p {
		text-transform: uppercase;
		font-family: $secondary-font;
	}
}
