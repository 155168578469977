/* Navigation
================================================== */
.main-nav {
	.nav {
		width: 100%;
		float: left;
		margin: 0;
		display: none;
		@include bp(large) {
			display: block;
		}
		li {
			float: left;
			position: relative;
			text-align: center;
			list-style: none;
			&.deeper.active {
				margin-bottom: 10px;
				& > ul {
					display: block;
					& > li {
						opacity: 1 !important;
					}
				}
			}
		}
		a {
			display: block;
			padding: 17px 11px;
			color: $white;
			font-size: 1em;
			text-decoration: none;
			text-transform: uppercase;
			line-height: 120%;
			@include bp(large) {
				padding: 17px 6px;
				font-size: 0.9em;
			}
			@include bp(xxlarge) {
				padding: 17px 11px;
				font-size: 1em;
			}
		}
		a:hover,
		.current > a,
		.active > a {
			color: $tertiary-colour;
		}
		i {
			margin-left: 5px;
		}
		.nav-child {
			margin-bottom: 25px;
		}
		ul {
			display: none;
			width: 100%;
			float: left;
			position: static;
			margin: 0;
			font-size: 0.8em;
			/*@include bp(large) {
        position: absolute;
        min-width: 150px;
        width: auto;
        top: 50px;
        left: 0;
        z-index: 9;
        font-size: 1em;
        background-color: $primary-colour;
      }*/
			&.active {
				display: block;
			}
			li {
				width: 100%;
				opacity: 0;
			}
			a {
				padding: 7px 11px;
			}
			ul {
				font-size: 1em;
			}
		}
		/*li:hover > ul {
      @include bp(large) {
        display: block;
      }
    }*/
		& > * {
			overflow: visable;
		}

		@include bp_max(large) {
			&.active,
			& > ul.active {
				display: block;
			}
			&.active a {
				display: block;
				padding: 0.6em;
			}
			&.active li,
			&.active ul ul {
				width: 100%;
				height: auto;
				float: left;
				display: block;
				font-size: 1em !important;
			}
			&.active ul ul {
				display: block;
				position: inherit;
				top: auto;
			}
		}
	}
}

/* Main Navigation
================================================== */
.main-nav {
	visibility: hidden;
	display: block;
	position: fixed;
	width: 325px;
	min-height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: auto;
	z-index: 999;
	background: $secondary-colour;
	-webkit-transition: -webkit-transform 1000ms cubic-bezier(0.85, 0, 0.15, 1),
		visibility 1000ms;
	-moz-transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1),
		visibility 1000ms;
	-o-transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1),
		visibility 1000ms;
	transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1), visibility 1000ms; /* easeInOutCirc */
	@include transform(translateX(100%));
	@include box-shadow(3px 0px 7px 0px rgba(0, 0, 0, 0.25));
	-webkit-backface-visibility: hidden;
	overflow: hidden;
	.scroll-wrap {
		width: 345px;
		height: 100%;
		overflow: auto;
		padding: 20px;
	}
	&.active {
		@include transform(translateX(0));
		visibility: visible;
	}
	.nav {
		display: block;
		li {
			width: 100%;
		}
	}
}

.menu-link {
	button {
		width: 35px;
		height: 35px;
		position: relative;
		z-index: 1;
		display: block;
		float: right;
		cursor: pointer;
		margin: 20px;
		background: transparent;
		outline: none;
	}
	span,
	span:before,
	span:after {
		width: 35px;
		height: 5px;
		position: absolute;
		top: 5px;
		right: 0;
		display: block;
		content: "";
		background: $white;
		@include border-radius(1px);
		@include transition(all 500ms ease-in-out);
	}

	span {
		&:before {
			top: -10px;
		}
		&:after {
			top: auto;
			bottom: -10px;
		}
	}

	&:hover {
		background: transparent;
		span,
		span:before,
		span:after {
			background-color: $light-blue;
		}
	}

	&.active {
		span {
			background: transparent;
			&:before,
			&:after {
				top: 0;
			}
			&:before {
				@include rotate(45deg);
			}
			&:after {
				@include rotate(-45deg);
			}
		}
	}

	// Main Nav Button
	.main-menu-button & {
		display: block;
		/*p, button {
            .inline-block();
        }*/
		p {
			float: right;
			display: none;
			margin: 16px 0;
			color: $light-blue;
			text-transform: uppercase;
			@include bp(large) {
				display: block;
			}
		}
	}

	// Sidebar Nav Button
	.main-nav & {
		float: right;
		span,
		span:before,
		span:after {
			background-color: $tertiary-colour;
			width: 22px;
			height: 3px;
		}
		span {
			top: 10px;
		}
		&:hover {
			span,
			span:before,
			span:after {
				background-color: $white;
			}
		}
		&.active {
			span {
				background-color: transparent;
			}
		}
	}
}

.sitemap-menu {
	width: 100%;
	float: left;
}
