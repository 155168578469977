@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
/* #Reset & Basics (Inspired by E. Meyers)
================================================== */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  position: relative;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

main .latestnews-item .newshead .calendar span,
main .latestnews-item .newshead .calendar .position1, .contact-form #form13 .wdform-label, .footer-main ul li, .newsletter-section #form12 .wdform-field,
.newsletter-section #form12 div[type=type_submit_reset] .wdform-element-section, .key-services li, .circle-button p,
.small-button p,
#form12 .button-submit p,
.ba-form-submit-btn p, .circle-button,
.small-button,
#form12 .button-submit,
.ba-form-submit-btn {
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.full-client-list img, .full-client-list span, .footer-main ul img {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.team-members .team-image, .newsletter-section, .second-section {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* Balbooa Forms Component 
================================================== */
.com-baforms-wrapper .ba-form-page .ba-form-field-item {
  margin: 0 0 1rem 0;
}
.com-baforms-wrapper .ba-form-page fieldset {
  padding: 0;
  border: none;
}
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input,
.com-baforms-wrapper .ba-form-page .ba-form-page-navigation-title,
.com-baforms-wrapper .ba-form-page .upload-file-btn,
.com-baforms-wrapper .ba-form-page .upload-file-input,
.com-baforms-wrapper .ba-form-page .ba-field-container select,
.com-baforms-wrapper .ba-form-page .ba-field-container textarea[readonly],
.com-baforms-wrapper .ba-form-page .ba-field-container textarea,
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=text],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=number],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=password],
.com-baforms-wrapper .ba-form-page .ba-field-container input[type=email],
.com-baforms-wrapper .ba-form-page .ba-field-container select option,
.com-baforms-wrapper .ba-form-page .ba-form-field-item .ba-form-checkbox-wrapper .ba-checkbox-title {
  font-weight: normal;
  font-size: 1rem !important;
  color: #0a0a0a !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-acceptance-field .ba-field-container {
  margin-top: 2rem;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-wrapper .ba-form-submit-btn-wrapper {
  width: auto !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-field {
  margin-bottom: 0 !important;
}
.com-baforms-wrapper .ba-form-page .ba-form-submit-btn {
  margin-top: 2rem;
  margin-bottom: 0;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #ffffff;
}
table td,
table th {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
table td *,
table th * {
  margin: 0;
}
table th {
  background-color: #c9a66f;
  font-size: 1.1rem;
}
table th * {
  color: #ffffff;
}

table tr:nth-child(2n) {
  background-color: #edf0ef;
}

img {
  max-width: 100%;
  height: auto;
}

body figure[data-wf-figure] {
  display: block;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 160%;
  color: #0a0a0a;
  font-weight: 400;
  background: #ffffff;
}

main,
details {
  display: block;
}

a {
  color: #c9a66f;
  background-color: transparent;
  text-decoration: none;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
}
a:hover {
  color: #0a0a0a;
}

b,
.b,
strong,
.strong {
  font-weight: 700;
}

em,
.em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small,
.small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template,
[hidden] {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  line-height: 160%;
  font-weight: 400;
  margin: 0.5rem 0;
}

h1,
.h1 {
  font-size: 2.6rem;
}

h2,
.h2 {
  font-size: 2.2rem;
}

h3,
.h3 {
  font-size: 1.8rem;
}

h4,
.h4 {
  font-size: 1.6rem;
}

h5,
.h5 {
  font-size: 1.4rem;
}

h6,
.h6 {
  font-size: 1.4rem;
}

.button,
button,
input[type=submit],
input[type=reset],
input[type=button],
.com-baforms-wrapper .ba-form-submit-btn {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  background: #1d2a33;
  border: 0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  -webkit-transition: 400ms ease-in-out;
  -moz-transition: 400ms ease-in-out;
  -ms-transition: 400ms ease-in-out;
  -o-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
  -webkit-appearance: none;
}
.button:hover,
button:hover,
input[type=submit]:hover,
input[type=reset]:hover,
input[type=button]:hover,
.com-baforms-wrapper .ba-form-submit-btn:hover {
  color: #ffffff;
  background: #0e1519;
  border: 0;
}
.button:active,
button:active,
input[type=submit]:active,
input[type=reset]:active,
input[type=button]:active,
.com-baforms-wrapper .ba-form-submit-btn:active {
  border: 0;
}
.button:visited,
button:visited,
input[type=submit]:visited,
input[type=reset]:visited,
input[type=button]:visited,
.com-baforms-wrapper .ba-form-submit-btn:visited {
  color: #ffffff;
}

.button--full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.button--secondary {
  background: #c9a66f;
}
.button--secondary:hover {
  background: #be9451;
}
.button-wrap--center {
  text-align: center;
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-0-25rem {
  margin-left: 0.25rem;
}

.margin-left-0-5rem {
  margin-left: 0.5rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-1-5rem {
  margin-left: 1.5rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-left-3rem {
  margin-left: 3rem;
}

.margin-left-4rem {
  margin-left: 4rem;
}

.margin-left-5rem {
  margin-left: 5rem;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-0-25rem {
  margin-right: 0.25rem;
}

.margin-right-0-5rem {
  margin-right: 0.5rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-right-1-5rem {
  margin-right: 1.5rem;
}

.margin-right-2rem {
  margin-right: 2rem;
}

.margin-right-3rem {
  margin-right: 3rem;
}

.margin-right-4rem {
  margin-right: 4rem;
}

.margin-right-5rem {
  margin-right: 5rem;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-25rem {
  margin-top: 0.25rem;
}

.margin-top-0-5rem {
  margin-top: 0.5rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-1-5rem {
  margin-top: 1.5rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-top-3rem {
  margin-top: 3rem;
}

.margin-top-4rem {
  margin-top: 4rem;
}

.margin-top-5rem {
  margin-top: 5rem;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-25rem {
  margin-bottom: 0.25rem;
}

.margin-bottom-0-5rem {
  margin-bottom: 0.5rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-1-5rem {
  margin-bottom: 1.5rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-bottom-3rem {
  margin-bottom: 3rem;
}

.margin-bottom-4rem {
  margin-bottom: 4rem;
}

.margin-bottom-5rem {
  margin-bottom: 5rem;
}

.padding-0 {
  padding: 0;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.margin-0 {
  margin: 0;
}

.margin-0-25rem {
  margin: 0.25rem;
}

.margin-0-5rem {
  margin: 0.5rem;
}

.margin-1rem {
  margin: 1rem;
}

.margin-1-5rem {
  margin: 1.5rem;
}

.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: flex-end;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 30em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 100em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns, .newslist .row.collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row, .newslist .row.collapse > .latestnews-item > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns, .newslist .latestnews-item {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 48em) {
  .column, .columns, .newslist .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns, .newslist .row.row.latestnews-item {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns, .row .newslist .row.row.latestnews-item, .newslist .row .row.row.latestnews-item {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12, .newslist .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns, .newslist .small-up-1 > .latestnews-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns, .newslist .small-up-2 > .latestnews-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns, .newslist .small-up-3 > .latestnews-item {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns, .newslist .small-up-4 > .latestnews-item {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns, .newslist .small-up-5 > .latestnews-item {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns, .newslist .small-up-6 > .latestnews-item {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns, .newslist .small-up-7 > .latestnews-item {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns, .newslist .small-up-8 > .latestnews-item {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns, .newslist .small-collapse > .latestnews-item {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns, .newslist .small-uncollapse > .latestnews-item {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 30em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .smedium-offset-0 {
    margin-left: 0%;
  }
  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-offset-3 {
    margin-left: 25%;
  }
  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .smedium-offset-6 {
    margin-left: 50%;
  }
  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .smedium-offset-9 {
    margin-left: 75%;
  }
  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns, .newslist .smedium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns, .newslist .smedium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns, .newslist .smedium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns, .newslist .smedium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns, .newslist .smedium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns, .newslist .smedium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns, .newslist .smedium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns, .newslist .smedium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 30em) and (min-width: 30em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 30em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns, .newslist .row.smedium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .smedium-collapse > .column, .smedium-collapse > .columns, .newslist .smedium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns, .newslist .smedium-uncollapse > .latestnews-item {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

@media print, screen and (min-width: 48em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns, .newslist .medium-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns, .newslist .medium-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns, .newslist .medium-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns, .newslist .medium-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns, .newslist .medium-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns, .newslist .medium-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns, .newslist .medium-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns, .newslist .medium-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns, .newslist .row.medium-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns, .newslist .medium-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns, .newslist .medium-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 60em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns, .newslist .large-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns, .newslist .large-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns, .newslist .large-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns, .newslist .large-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns, .newslist .large-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns, .newslist .large-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns, .newslist .large-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns, .newslist .large-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.large-unstack > .column, .row.large-unstack > .columns, .newslist .row.large-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns, .newslist .large-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns, .newslist .large-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns, .newslist .xlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns, .newslist .xlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns, .newslist .xlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns, .newslist .xlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns, .newslist .xlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns, .newslist .xlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns, .newslist .xlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns, .newslist .xlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns, .newslist .xlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, .newslist .xlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns, .newslist .xlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns, .newslist .xlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns, .newslist .xlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns, .newslist .xlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns, .newslist .xlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns, .newslist .xlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns, .newslist .xlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns, .newslist .xlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns, .newslist .row.xlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .xlarge-collapse > .columns, .newslist .xlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns, .newslist .xlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 100em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns, .newslist .xxlarge-up-1 > .latestnews-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns, .newslist .xxlarge-up-2 > .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns, .newslist .xxlarge-up-3 > .latestnews-item {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns, .newslist .xxlarge-up-4 > .latestnews-item {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns, .newslist .xxlarge-up-5 > .latestnews-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns, .newslist .xxlarge-up-6 > .latestnews-item {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns, .newslist .xxlarge-up-7 > .latestnews-item {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns, .newslist .xxlarge-up-8 > .latestnews-item {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 100em) and (min-width: 100em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
  flex: 0 0 100%;
}
@media screen and (min-width: 100em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns, .newslist .row.xxlarge-unstack > .latestnews-item {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns, .newslist .xxlarge-collapse > .latestnews-item {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns, .newslist .xxlarge-uncollapse > .latestnews-item {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 48em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/
/* Main
================================================== */
main {
  width: 100%;
  float: left;
  position: relative;
  background-color: #d0bda2;
}
@media (min-width: 1600px) {
  main .full-height-column {
    display: table;
  }
  main .v-align {
    display: table-cell;
    vertical-align: middle;
    float: none;
  }
}
main .cover {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}
main .item-page a {
  text-decoration: underline;
}
main .item-page a:hover {
  text-decoration: none;
}

.intro,
.second-section {
  min-height: 450px;
  /*@include bp(xxlarge) {
     	min-height: 800px;
     	min-height: 1200px;
     }*/
}
@media (min-width: 960px) {
  .intro,
  .second-section {
    min-height: 800px;
  }
}

@media (max-width: 1199px) {
  .full-height-column {
    height: auto !important;
  }
}

.circle-button .circle-icon,
.circle-button .icon,
.small-button .circle-icon,
.small-button .icon,
#form12 .button-submit .circle-icon,
#form12 .button-submit .icon,
.ba-form-submit-btn .circle-icon,
.ba-form-submit-btn .icon {
  position: relative;
  z-index: 1;
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  top: 9px;
  cursor: pointer;
  margin: 0;
  outline: none;
  background-color: #1d2a33;
  border-radius: 50%;
  -webkit-transition: background-color 400ms ease-in-out;
  -moz-transition: background-color 400ms ease-in-out;
  -ms-transition: background-color 400ms ease-in-out;
  -o-transition: background-color 400ms ease-in-out;
  transition: background-color 400ms ease-in-out;
}
.circle-button .circle-icon i,
.circle-button .icon i,
.small-button .circle-icon i,
.small-button .icon i,
#form12 .button-submit .circle-icon i,
#form12 .button-submit .icon i,
.ba-form-submit-btn .circle-icon i,
.ba-form-submit-btn .icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #c9a66f;
  font-weight: bold;
  font-size: 2em;
  padding: 1px 0 0 0;
}
.circle-button:hover .circle-icon,
.small-button:hover .circle-icon,
#form12 .button-submit:hover .circle-icon,
.ba-form-submit-btn:hover .circle-icon {
  background-color: #d0bda2;
}
.circle-button p,
.small-button p,
#form12 .button-submit p,
.ba-form-submit-btn p {
  font-family: "Merriweather", serif;
  font-size: 1.4em;
  margin: 20px 0 0 10px;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}

.small-button .icon {
  width: 40px;
  height: 40px;
  top: 12px;
  background-color: transparent;
}
.small-button .title {
  margin-left: 0;
  font-size: 1.2em;
}

.label-warning {
  display: none;
}

/* Components
================================================== */
/* Header
================================================== */
header {
  position: relative;
  z-index: 10;
  width: 100%;
  float: left;
  /*margin-bottom: 30px;
     @include bp(smedium) {
     	margin-bottom: 50px;
     }
     @include bp(large) {
     	margin-bottom: 80px;
     }
     @include bp(xxlarge) {
     	margin-bottom: 120px;
     }*/
}

.header-logo {
  position: relative;
}
.header-logo img {
  width: 370px;
}
@media (max-width: 959px) {
  .header-logo img {
    width: 100%;
  }
}
.header-logo a {
  display: block;
}
.header-logo.patents-logo img {
  width: 312px;
}

.header-left {
  float: left;
}
@media (min-width: 480px) {
  .header-left {
    margin-right: 10px;
  }
}

.header-right {
  float: right;
}
@media (min-width: 480px) {
  .header-right {
    margin-left: 10px;
  }
}

@media (min-width: 960px) {
  .intro .header-logo:after {
    content: "";
    position: absolute;
    top: 70px;
    left: -10px;
    z-index: -1;
    width: 500px;
    height: 400px;
    background: url("template-image-path/logo-shadow.png") no-repeat 0 0;
  }
}
@media (min-width: 960px) and (min-width: 480px) {
  .intro .header-logo:after {
    top: 80px;
    left: 0;
  }
}
.intro .social-icons {
  width: 80px;
  float: right;
  display: none;
  clear: right;
}
@media (min-width: 960px) {
  .intro .social-icons {
    display: block;
  }
}
.intro .social-icons a {
  display: block;
  padding: 10px;
  text-align: center;
}
.intro .social-icons a i {
  font-size: 1.4em;
  color: #ffffff;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}
.intro .social-icons a i.fa-google-plus {
  font-size: 1.2em;
}
.intro .social-icons a:hover i {
  color: #668aae;
}

/* Navigation
================================================== */
.main-nav .nav {
  width: 100%;
  float: left;
  margin: 0;
  display: none;
  /*li:hover > ul {
      @include bp(large) {
        display: block;
      }
    }*/
}
@media (min-width: 960px) {
  .main-nav .nav {
    display: block;
  }
}
.main-nav .nav li {
  float: left;
  position: relative;
  text-align: center;
  list-style: none;
}
.main-nav .nav li.deeper.active {
  margin-bottom: 10px;
}
.main-nav .nav li.deeper.active > ul {
  display: block;
}
.main-nav .nav li.deeper.active > ul > li {
  opacity: 1 !important;
}
.main-nav .nav a {
  display: block;
  padding: 17px 11px;
  color: #ffffff;
  font-size: 1em;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 120%;
}
@media (min-width: 960px) {
  .main-nav .nav a {
    padding: 17px 6px;
    font-size: 0.9em;
  }
}
@media (min-width: 1600px) {
  .main-nav .nav a {
    padding: 17px 11px;
    font-size: 1em;
  }
}
.main-nav .nav a:hover,
.main-nav .nav .current > a,
.main-nav .nav .active > a {
  color: #17235a;
}
.main-nav .nav i {
  margin-left: 5px;
}
.main-nav .nav .nav-child {
  margin-bottom: 25px;
}
.main-nav .nav ul {
  display: none;
  width: 100%;
  float: left;
  position: static;
  margin: 0;
  font-size: 0.8em;
  /*@include bp(large) {
       position: absolute;
       min-width: 150px;
       width: auto;
       top: 50px;
       left: 0;
       z-index: 9;
       font-size: 1em;
       background-color: $primary-colour;
     }*/
}
.main-nav .nav ul.active {
  display: block;
}
.main-nav .nav ul li {
  width: 100%;
  opacity: 0;
}
.main-nav .nav ul a {
  padding: 7px 11px;
}
.main-nav .nav ul ul {
  font-size: 1em;
}
.main-nav .nav > * {
  overflow: visable;
}
@media (max-width: 959px) {
  .main-nav .nav.active, .main-nav .nav > ul.active {
    display: block;
  }
  .main-nav .nav.active a {
    display: block;
    padding: 0.6em;
  }
  .main-nav .nav.active li, .main-nav .nav.active ul ul {
    width: 100%;
    height: auto;
    float: left;
    display: block;
    font-size: 1em !important;
  }
  .main-nav .nav.active ul ul {
    display: block;
    position: inherit;
    top: auto;
  }
}

/* Main Navigation
================================================== */
.main-nav {
  visibility: hidden;
  display: block;
  position: fixed;
  width: 325px;
  min-height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 999;
  background: #c9a66f;
  -webkit-transition: -webkit-transform 1000ms cubic-bezier(0.85, 0, 0.15, 1), visibility 1000ms;
  -moz-transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1), visibility 1000ms;
  -o-transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1), visibility 1000ms;
  transition: transform 1000ms cubic-bezier(0.85, 0, 0.15, 1), visibility 1000ms; /* easeInOutCirc */
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-box-shadow: 3px 0px 7px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 3px 0px 7px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 0px 7px 0px rgba(0, 0, 0, 0.25);
  -webkit-backface-visibility: hidden;
  overflow: hidden;
}
.main-nav .scroll-wrap {
  width: 345px;
  height: 100%;
  overflow: auto;
  padding: 20px;
}
.main-nav.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
}
.main-nav .nav {
  display: block;
}
.main-nav .nav li {
  width: 100%;
}

.menu-link button {
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 1;
  display: block;
  float: right;
  cursor: pointer;
  margin: 20px;
  background: transparent;
  outline: none;
}
.menu-link span,
.menu-link span:before,
.menu-link span:after {
  width: 35px;
  height: 5px;
  position: absolute;
  top: 5px;
  right: 0;
  display: block;
  content: "";
  background: #ffffff;
  border-radius: 1px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.menu-link span:before {
  top: -10px;
}
.menu-link span:after {
  top: auto;
  bottom: -10px;
}
.menu-link:hover {
  background: transparent;
}
.menu-link:hover span,
.menu-link:hover span:before,
.menu-link:hover span:after {
  background-color: #668aae;
}
.menu-link.active span {
  background: transparent;
}
.menu-link.active span:before, .menu-link.active span:after {
  top: 0;
}
.menu-link.active span:before {
  transform: rotate(45degdeg);
}
.menu-link.active span:after {
  transform: rotate(-45degdeg);
}
.main-menu-button .menu-link {
  display: block;
  /*p, button {
            .inline-block();
        }*/
}
.main-menu-button .menu-link p {
  float: right;
  display: none;
  margin: 16px 0;
  color: #668aae;
  text-transform: uppercase;
}
@media (min-width: 960px) {
  .main-menu-button .menu-link p {
    display: block;
  }
}
.main-nav .menu-link {
  float: right;
}
.main-nav .menu-link span,
.main-nav .menu-link span:before,
.main-nav .menu-link span:after {
  background-color: #17235a;
  width: 22px;
  height: 3px;
}
.main-nav .menu-link span {
  top: 10px;
}
.main-nav .menu-link:hover span,
.main-nav .menu-link:hover span:before,
.main-nav .menu-link:hover span:after {
  background-color: #ffffff;
}
.main-nav .menu-link.active span {
  background-color: transparent;
}

.sitemap-menu {
  width: 100%;
  float: left;
}

/* Banner / Slider
================================================== */
/* Page Specific Styling
================================================== */
/* Sections 
================================================== */
section {
  position: relative;
  overflow: hidden;
}

/* Second Section
================================================== */
.second-section {
  width: 100%;
  float: left;
  padding: 10%;
  background: url("${template-image-path}second-section-bg.jpg") no-repeat 50% 50%;
  text-align: center;
}
@media (min-width: 1600px) {
  .second-section {
    width: 70%;
  }
}
@media (min-width: 1600px) {
  .second-section {
    width: 80%;
  }
}
.second-section h1 {
  position: relative;
  margin-bottom: 40px;
  font-size: 1.5em;
}
.second-section h1:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
  width: 30%;
  height: 2px;
  margin: auto;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(77, 91, 105) 50%, rgba(0, 0, 0, 0) 100%);
}
.second-section h2 {
  width: 100%;
  margin: 0 auto 40px auto;
  font-family: "Merriweather", serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 140%;
}
@media (min-width: 960px) {
  .second-section h2 {
    font-size: 2.4em;
  }
}
@media (min-width: 1600px) {
  .second-section h2 {
    width: 70%;
    font-size: 3em;
  }
}

.second-section-content {
  margin-bottom: 150px;
}

.key-services ul {
  margin: 0 0 15px 0;
}
.key-services li {
  margin: 8px;
  list-style: none;
  font-size: 1.3em;
}
.key-services a {
  color: #1d2a33;
  text-transform: uppercase;
  font-weight: 200;
  font-family: "montserratultra_light";
}
.key-services a:hover i {
  color: #1d2a33;
}
.key-services a i {
  color: #c9a66f;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}
.key-services p {
  text-transform: uppercase;
  font-family: "Merriweather", serif;
}

/* Sidebar
================================================== */
.sidebar {
  width: 100%;
  float: left;
  overflow: hidden;
}
@media (min-width: 1600px) {
  .sidebar {
    width: 30%;
  }
}
@media (min-width: 1600px) {
  .sidebar {
    width: 20%;
  }
}
.sidebar .moduletable {
  padding: 10%;
}
@media (min-width: 480px) {
  .sidebar .moduletable {
    padding: 5%;
  }
}
@media (min-width: 1600px) {
  .sidebar .moduletable {
    padding: 10%;
  }
}

.clients {
  background: #111b58;
  background: -moz-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #111b58), color-stop(100%, #004990));
  background: -webkit-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -o-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -ms-linear-gradient(top, #111b58 0%, #004990 100%);
  background: linear-gradient(to bottom, #111b58 0%, #004990 100%);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr="#111b58", endColorstr="#004990");
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#111b58', endColorstr='#004990')";
  min-height: 70%;
  text-align: center;
}
.clients ul,
.clients li {
  width: 100%;
  float: left;
  margin: 0;
  text-align: center;
}
.clients ul {
  margin: 20px 0 60px 0;
}
.clients li {
  padding: 20px 0;
}
.clients img {
  margin: 0 auto;
  display: block;
  height: 60px;
}
.clients p {
  color: #ffffff;
  font-size: 1.1em;
}
.clients .small-button .icon i {
  color: #c6c6c6;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}
.clients .small-button p {
  margin-left: 0;
  color: #c6c6c6;
}
.clients .small-button:hover .icon i {
  color: #c9a66f;
}
.clients .small-button:hover p {
  color: #c9a66f;
}

.twitter-feed {
  background: #738a9c;
  background: -moz-linear-gradient(top, #738a9c 0%, #526783 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #738a9c), color-stop(100%, #526783));
  background: -webkit-linear-gradient(top, #738a9c 0%, #526783 100%);
  background: -o-linear-gradient(top, #738a9c 0%, #526783 100%);
  background: -ms-linear-gradient(top, #738a9c 0%, #526783 100%);
  background: linear-gradient(to bottom, #738a9c 0%, #526783 100%);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr="#738a9c", endColorstr="#526783");
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#738a9c', endColorstr='#526783')";
  min-height: 30%;
  text-align: center;
}
.twitter-feed iframe#twitter-widget-0 {
  width: 100% !important;
}
.twitter-feed .small-button .icon {
  background-color: transparent;
}
.twitter-feed .small-button .icon i {
  color: #dee9f2;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}
.twitter-feed .small-button p {
  margin-left: 0;
  color: #dee9f2;
}
.twitter-feed .small-button:hover .icon i {
  color: #87bde5;
}
.twitter-feed .small-button:hover p {
  color: #87bde5;
}

/* News
================================================== */
.newslist .latestnews-items {
  flex-flow: column !important;
  align-items: normal !important;
  -webkit-justify-content: normal !important;
  -ms-flex-pack: normal !important;
  justify-content: normal !important;
  overflow: visible !important;
}
@media (min-width: 768px) {
  .newslist .latestnews-items {
    flex-flow: row wrap !important;
  }
}
.newslist .latestnews-item {
  flex: 1;
  padding: 0 1rem 2rem !important;
}
@media (min-width: 768px) {
  .newslist .latestnews-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 960px) {
  .newslist .latestnews-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}
.newslist .latestnews-item > a {
  display: block;
  height: 100%;
}
.newslist .latestnews-item > a:hover .detail_category,
.newslist .latestnews-item > a:hover .detail_tags {
  opacity: 1;
}
.newslist .latestnews-item > a:hover .link a {
  background-color: #1d2a33;
}
.newslist .latestnews-item > a:hover .link a span {
  color: #ffffff;
}
.newsSection .newslist .latestnews-item {
  border: 0;
}
.newslist .latestnews-item.active {
  opacity: 1 !important;
}
.newslist .newshead {
  float: none !important;
  margin: 0 !important;
}
.newslist .innerpicture {
  width: 100%;
}
.newslist .innerpicture:after {
  content: "";
  display: block;
  padding-bottom: 60%;
}
.newslist .innerpicture img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.newslist .news {
  position: relative !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0rem !important;
  background-color: transparent !important;
  border: none !important;
}
.newslist .newstitle {
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif !important;
  font-weight: normal !important;
  line-height: 160% !important;
  margin: 0 0 0.5em 0 !important;
}
.newslist .newstitle a {
  color: #1d2a33;
}
.newslist .newstitle a:hover {
  color: #c9a66f;
}
.newslist .newsinfo {
  padding: 1.5rem 1rem 4rem !important;
}
.newslist .newsinfo * {
  color: #1d2a33;
}
.newslist .newsintro {
  line-height: 160% !important;
}
.newslist .detail_category,
.newslist .detail_tags {
  opacity: 1;
  display: inline-flex;
  padding: 0.4rem 0.8rem;
  border-radius: 50px;
  background: #edf0ef;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  color: #1d2a33;
  transition: 400ms ease-in-out;
}
@media (min-width: 768px) {
  .newslist .detail_category,
  .newslist .detail_tags {
    opacity: 0.25;
  }
}
.newslist .detail_date {
  display: flex;
  align-items: center;
  margin: 0 0 0.25rem 0;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 0.6rem;
  font-weight: 400;
}
.newslist .delimiter {
  display: none;
}
.newslist .link {
  width: 100% !important;
  margin-top: 1.5em !important;
}
.newslist .link a {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 0;
  background-color: #1d2a33;
  color: #1d2a33;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.newslist .link span {
  transition: 400ms ease-in-out;
}
@media (min-width: 768px) {
  .newslist--full-width .latestnews-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.newslist--full-details .latestnews-item .newsinfo {
  padding: 1.5rem 1rem !important;
}
.newslist--full-details .latestnews-item ul,
.newslist--full-details .latestnews-item p {
  margin-bottom: 1rem !important;
}

/* News Page
  ================================================== */
.news-item-article .main-content {
  flex-direction: column;
  padding: 0;
}
.news-item-article .published {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.news-item-article .com-content-image,
.news-item-article .item-page {
  max-width: 960px;
  width: 100%;
  margin: auto;
}
.news-item-article .com-content-image {
  margin-bottom: 0;
}
.news-item-article .com-content-image figure {
  position: relative;
  margin: 0;
  background-color: #ffffff;
}
.news-item-article .com-content-image img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 600px;
  object-fit: cover;
}
.news-item-article .item-page {
  padding: 3em;
  background-color: #ffffff;
}
.news-item-article .item-page * {
  color: #0a0a0a;
}
.news-item-article .article-info.muted {
  display: block;
  color: #1d2a33;
  margin-bottom: 2em;
}

.sharethis-inline-share-buttons {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-info.muted {
  display: none;
}

/* Newsletter Signup
================================================== */
.newsletter-section {
  width: 100%;
  float: left;
  padding: 10%;
  background: url("${template-image-path}/newsletter-bg.jpg") no-repeat 50% 50%;
  text-align: center;
}
@media (min-width: 1600px) {
  .newsletter-section {
    width: 65%;
  }
}
.newsletter-section h2 {
  position: relative;
  margin-bottom: 40px;
  font-size: 1.5em;
  color: #ffffff;
}
.newsletter-section h2:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
  width: 30%;
  height: 2px;
  margin: auto;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) 100%);
}
.newsletter-section .title {
  width: 100%;
  margin: 0 auto 40px auto;
  font-family: "Merriweather", serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 140%;
  color: #ffffff;
}
@media (min-width: 960px) {
  .newsletter-section .title {
    font-size: 2.4em;
  }
}
@media (min-width: 1600px) {
  .newsletter-section .title {
    width: 70%;
    font-size: 3em;
  }
}
.newsletter-section #form12 .wdform_page {
  background: transparent;
}
.newsletter-section #form12 .wdform-label-section {
  display: none;
}
.newsletter-section #form12 .wdform_section {
  width: 100%;
}
.newsletter-section #form12 .wdform_column {
  float: none;
}
.newsletter-section #form12 .wdform-field,
.newsletter-section #form12 .wdform-element-section {
  width: 100% !important;
  float: left;
  text-align: center;
}
.newsletter-section #form12 #wdform_1_element12 {
  position: relative;
  width: 100%;
  height: auto;
  background: transparent;
  border: none;
  font-size: 1.2em;
  font-weight: 200;
  font-style: normal;
  color: #16255d;
  text-transform: uppercase;
  text-align: center;
  font-family: "montserratultra_light";
  -webkit-backface-visibility: hidden;
  -webkit-appearance: none;
  outline: none;
}
@media (min-width: 480px) {
  .newsletter-section #form12 #wdform_1_element12 {
    font-size: 1.6em;
  }
}
@media (min-width: 960px) {
  .newsletter-section #form12 #wdform_1_element12 {
    font-size: 2em;
  }
}
@media (min-width: 1600px) {
  .newsletter-section #form12 #wdform_1_element12 {
    font-size: 2.2em;
  }
}
.newsletter-section #form12 #wdform_1_element12::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ffffff;
}
.newsletter-section #form12 #wdform_1_element12:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ffffff;
  opacity: 1;
}
.newsletter-section #form12 #wdform_1_element12::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ffffff;
  opacity: 1;
}
.newsletter-section #form12 #wdform_1_element12:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}
.newsletter-section #form12 #wdform_1_element12.input_active::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #16255d;
}
.newsletter-section #form12 #wdform_1_element12.input_active:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #16255d;
}
.newsletter-section #form12 #wdform_1_element12.input_active::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #16255d;
}
.newsletter-section #form12 #wdform_1_element12.input_active:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #16255d;
}
.newsletter-section #form12 div[type=type_submitter_mail] {
  position: relative;
}
.newsletter-section #form12 div[type=type_submitter_mail]:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  width: 80%;
  height: 2px;
  margin: auto;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(175, 109, 17) 50%, rgba(0, 0, 0, 0) 100%);
}
.newsletter-section #form12 .button-submit {
  background: transparent;
  font-size: 1em;
}
.newsletter-section #form12 .button-submit p {
  color: #ffffff;
}
.newsletter-section #form12 .button-submit .circle-icon {
  background-color: #d4bb80;
}
.newsletter-section #form12 .button-submit:hover .circle-icon {
  background-color: #c9a66f;
}
.newsletter-section #form12 .button-submit i {
  color: #728096;
}

.com-baforms-wrapper .ba-form-page {
  padding: 0 !important;
  background: transparent !important;
}
.com-baforms-wrapper .ba-field-label-wrapper {
  display: none;
}
.com-baforms-wrapper .field-underline .ba-field-container:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  width: 80%;
  height: 2px;
  margin: auto;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(175, 109, 17) 50%, rgba(0, 0, 0, 0) 100%);
}
.com-baforms-wrapper .ba-field-container input[type=email] {
  position: relative;
  font-size: 1.4rem;
  font-weight: 200;
  font-style: normal;
  color: #16255d;
  text-transform: uppercase;
  text-align: center;
  font-family: "montserratultra_light";
  background: transparent !important;
  -webkit-backface-visibility: hidden;
  -webkit-appearance: none;
}
@media (min-width: 960px) {
  .com-baforms-wrapper .ba-field-container input[type=email] {
    font-size: 1.8rem;
  }
}
.com-baforms-wrapper .ba-field-container input[type=email]::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ffffff;
  font-size: 1.4rem;
}
@media (min-width: 960px) {
  .com-baforms-wrapper .ba-field-container input[type=email]::-webkit-input-placeholder {
    font-size: 1.8rem;
  }
}
.com-baforms-wrapper .ba-field-container input[type=email]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ffffff;
  opacity: 1;
  font-size: 1.4rem;
}
@media (min-width: 960px) {
  .com-baforms-wrapper .ba-field-container input[type=email]:-moz-placeholder {
    font-size: 1.8rem;
  }
}
.com-baforms-wrapper .ba-field-container input[type=email]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ffffff;
  opacity: 1;
  font-size: 1.4rem;
}
@media (min-width: 960px) {
  .com-baforms-wrapper .ba-field-container input[type=email]::-moz-placeholder {
    font-size: 1.8rem;
  }
}
.com-baforms-wrapper .ba-field-container input[type=email]:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
  font-size: 1.4rem;
}
@media (min-width: 960px) {
  .com-baforms-wrapper .ba-field-container input[type=email]:-ms-input-placeholder {
    font-size: 1.8rem;
  }
}
.com-baforms-wrapper .ba-field-container input[type=email]:not([readonly]):focus {
  background: transparent !important;
}
.com-baforms-wrapper .ba-form-acceptance-html {
  font-size: 0.8rem !important;
}
.com-baforms-wrapper .ba-form-acceptance-field {
  display: flex;
  justify-content: center;
  margin: 0 !important;
}
.com-baforms-wrapper .ba-form-acceptance-field .ba-field-container {
  width: auto !important;
  margin: 0 !important;
}
.com-baforms-wrapper .ba-form-submit-btn-wrapper {
  justify-content: center !important;
}
.com-baforms-wrapper .ba-form-acceptance-html {
  color: #ffffff !important;
}
.com-baforms-wrapper .ba-form-submit-btn {
  padding: 1rem 0 0 0 !important;
  background: transparent !important;
}
.com-baforms-wrapper .ba-form-submit-btn .circle-icon {
  top: auto;
  margin-right: 0.5rem;
  background-color: #d4bb80;
}
.com-baforms-wrapper .ba-form-submit-btn:hover .circle-icon {
  background-color: #c9a66f;
}
.com-baforms-wrapper .ba-form-submit-btn i {
  margin: 0 !important;
  color: #728096;
  font-size: 1.8rem !important;
}
.com-baforms-wrapper .ba-form-submit-title {
  font-family: "Merriweather", serif;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.4rem;
}

/* Footer
================================================== */
#ccc-icon {
  margin: 0px !important;
}

footer {
  width: 100%;
  float: left;
}

.footer-right {
  width: 100%;
  float: left;
  overflow: hidden;
}
@media (min-width: 1600px) {
  .footer-right {
    width: 35%;
  }
}

.footer-contact {
  width: 100%;
  min-height: 60%;
  float: left;
  padding: 15% 10%;
  background-color: #16255d;
}
.footer-contact h3,
.footer-contact p,
.footer-contact a {
  color: #ffffff;
}
.footer-contact h3 {
  font-size: 1.5em;
}
.footer-contact p {
  font-size: 0.9em;
}
.footer-contact a:hover p {
  color: #c9a66f;
}
.footer-contact .small-button {
  float: left;
  clear: both;
}
.footer-contact .small-button i {
  color: #c9a66f;
}
@media (min-width: 480px) {
  .footer-contact .phone-button .icon,
  .footer-contact .phone-button i {
    cursor: default;
  }
}
.footer-contact .email-button i {
  font-size: 1.5em;
}

.join-us,
.get-in-touch {
  width: 100%;
  float: left;
}

.join-us {
  margin-bottom: 120px;
}

.footer-main {
  width: 100%;
  min-height: 40%;
  float: left;
  padding: 10%;
  background-color: #526783;
}
.footer-main ul {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
  padding: 5px;
  text-align: center;
}
@media (min-width: 1600px) {
  .footer-main ul {
    margin: 0 0 50px 0;
  }
}
.footer-main ul li {
  width: 36%;
  height: 75px;
  position: relative;
  margin: 0 5%;
  padding: 0 3px;
  list-style: none;
  overflow: hidden;
}
@media (min-width: 480px) {
  .footer-main ul li {
    width: 24%;
    margin: 0;
  }
}
@media (min-width: 960px) {
  .footer-main ul li {
    width: 16%;
  }
}
@media (min-width: 1600px) {
  .footer-main ul li {
    width: 24%;
  }
}
@media (min-width: 1600px) {
  .footer-main ul li {
    width: 16%;
  }
}
.footer-main ul img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  padding: 10px;
}

.footer-links,
.footer-details {
  text-align: center;
}
.footer-links a,
.footer-details a {
  color: #ffffff;
}
.footer-links a:hover,
.footer-details a:hover {
  color: #bec6d7;
}

.footer-links {
  margin-bottom: 30px;
}
.footer-links a {
  margin: 5px 30px;
  font-size: 0.9em;
}
@media (min-width: 1600px) {
  .footer-links a {
    font-size: 1em;
  }
}

.footer-details {
  font-size: 0.8em;
}
.footer-details p {
  color: #bec6d7;
}

.primary-colour {
  color: #1d2a33;
}

.secondary-colour {
  color: #c9a66f;
}

.tertiary-colour {
  color: #17235a;
}

/* Contact Page
================================================== */
@media (max-width: 1199px) {
  .internal main .column.full-height-column.contact-left, .internal main .full-height-column.contact-left.columns, .internal main .newslist .full-height-column.contact-left.latestnews-item, .newslist .internal main .full-height-column.contact-left.latestnews-item, .internal main .column.full-height-column.contact-right, .internal main .full-height-column.contact-right.columns, .internal main .newslist .full-height-column.contact-right.latestnews-item, .newslist .internal main .full-height-column.contact-right.latestnews-item {
    width: 100%;
  }
}

.contact-left,
.contact-right {
  background-color: #536883;
}
.contact-left *,
.contact-left a:hover,
.contact-right *,
.contact-right a:hover {
  color: #ffffff;
}

.contact-form #form13 {
  width: 100%;
  float: left;
}
.contact-form #form13 .wdform_page {
  background-color: transparent;
}
.contact-form #form13 .wdform-label {
  padding: 2px 5px 10px 0;
  color: #ffffff;
}
.contact-form #form13 .wdform-label-section {
  font-size: 1.2em;
  font-family: "Montserrat", sans-serif;
}
.contact-form #form13 input[type=text],
.contact-form #form13 textarea {
  border: none;
  padding: 15px !important;
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
}
.contact-form #form13 .wdform_column,
.contact-form #form13 .wdform-field,
.contact-form #form13 .wdform-label-section,
.contact-form #form13 .wdform-element-section {
  width: 100% !important;
}
.contact-form #form13 .wdform_section,
.contact-form #form13 .wdform-field {
  display: block;
}
.contact-form #form13 .wdform-page-and-images {
  display: block !important;
}
.contact-form #form13 div[type=type_submit_reset] .wdform-label-section {
  display: none !important;
}
.contact-form #form13 .button-submit {
  margin: 20px 0 0 0;
  font-size: 1.2em;
}

.internal main .column.full-height-column.contact-right, .internal main .full-height-column.contact-right.columns, .internal main .newslist .full-height-column.contact-right.latestnews-item, .newslist .internal main .full-height-column.contact-right.latestnews-item {
  display: block;
  padding: 0;
}
.internal main .column.full-height-column.contact-right .moduletable, .internal main .full-height-column.contact-right.columns .moduletable, .internal main .newslist .full-height-column.contact-right.latestnews-item .moduletable, .newslist .internal main .full-height-column.contact-right.latestnews-item .moduletable {
  min-height: 45%;
  position: relative;
}
@media (min-width: 1600px) {
  .internal main .column.full-height-column.contact-right .moduletable, .internal main .full-height-column.contact-right.columns .moduletable, .internal main .newslist .full-height-column.contact-right.latestnews-item .moduletable, .newslist .internal main .full-height-column.contact-right.latestnews-item .moduletable {
    margin-top: 80px;
  }
}
.internal main .column.full-height-column.contact-right .address, .internal main .full-height-column.contact-right.columns .address, .internal main .newslist .full-height-column.contact-right.latestnews-item .address, .newslist .internal main .full-height-column.contact-right.latestnews-item .address {
  width: 100%;
  float: left;
  min-height: 40%;
}
.internal main .column.full-height-column.contact-right .address ul, .internal main .full-height-column.contact-right.columns .address ul, .internal main .newslist .full-height-column.contact-right.latestnews-item .address ul, .newslist .internal main .full-height-column.contact-right.latestnews-item .address ul {
  width: 100%;
  margin: 0;
}
.internal main .column.full-height-column.contact-right .address li, .internal main .full-height-column.contact-right.columns .address li, .internal main .newslist .full-height-column.contact-right.latestnews-item .address li, .newslist .internal main .full-height-column.contact-right.latestnews-item .address li {
  list-style: none;
  padding: 0;
}

.contact-right {
  background-color: #72899b;
}
.contact-right .address p {
  margin-bottom: 5px;
}

#map-canvas {
  width: 100%;
  height: 100%;
  min-height: 400px;
}
@media (min-width: 1600px) {
  #map-canvas {
    min-height: 300px;
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.endorsements ul {
  position: relative;
}
.endorsements li {
  width: 100%;
  right: 0;
  margin: 40px 0;
  text-align: left;
  list-style: none;
  display: table !important;
  /*.testimonial-image, img {
      width: 100px;
      float: left;
      margin-right: 10px;
  }*/
}
.endorsements li p {
  font-size: 1.2em;
}
.endorsements li .title {
  color: #17235a;
  font-size: 1em;
  margin-top: 20px;
}
.endorsements li .testimonial {
  display: table-cell;
  vertical-align: middle;
  border-left: 15px solid transparent;
}
.endorsements li .testimonial-image, .endorsements li img {
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.endorsements li img {
  margin: 0 !important;
}
.endorsements li:before {
  content: open-quote open-quote;
  position: absolute;
  top: 45%;
  left: 65px;
  z-index: -1;
  font-family: Georgia, Times, Times New Roman, serif;
  color: #d0bda2;
  font-size: 25em;
  letter-spacing: -20px;
}
.endorsements .testimonial-button {
  width: 100%;
  float: left;
  clear: both;
}

.home-endorsements li {
  margin: auto;
}
@media (min-width: 1600px) {
  .home-endorsements li {
    width: 70%;
  }
}

@media (min-width: 480px) {
  .internal .endorsements li {
    margin: 70px 0;
  }
  .internal .endorsements .testimonial-image, .internal .endorsements img {
    width: 150px;
  }
}

.endorsements ul {
  position: relative;
}
.endorsements li {
  width: 100%;
  right: 0;
  margin: 40px 0;
  text-align: left;
  list-style: none;
  display: table !important;
  /*.testimonial-image, img {
      width: 100px;
      float: left;
      margin-right: 10px;
  }*/
}
.endorsements li p {
  font-size: 1.2em;
}
.endorsements li .title {
  color: #17235a;
  font-size: 1em;
  margin-top: 20px;
}
.endorsements li .testimonial {
  display: table-cell;
  vertical-align: middle;
  border-left: 15px solid transparent;
}
.endorsements li .testimonial-image, .endorsements li img {
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.endorsements li img {
  margin: 0 !important;
}
.endorsements li:before {
  content: open-quote open-quote;
  position: absolute;
  top: 45%;
  left: 65px;
  z-index: -1;
  font-family: Georgia, Times, Times New Roman, serif;
  color: #d0bda2;
  font-size: 25em;
  letter-spacing: -20px;
}
.endorsements .testimonial-button {
  width: 100%;
  float: left;
  clear: both;
}

.home-endorsements li {
  margin: auto;
}
@media (min-width: 1600px) {
  .home-endorsements li {
    width: 70%;
  }
}

@media (min-width: 480px) {
  .internal .endorsements li {
    margin: 70px 0;
  }
  .internal .endorsements .testimonial-image, .internal .endorsements img {
    width: 150px;
  }
}

/* Internal Pages
================================================== */
#page_about-pb main {
  background-color: #536883;
}
#page_about-pb main * {
  color: #ffffff;
}

#page_services main {
  background-color: #f3f3e9;
}
#page_services main * {
  color: #664615;
}

#page_ip-areas main {
  background-color: #505c85;
}
#page_ip-areas main * {
  color: #ffffff;
}

.portrait {
  width: 400px;
}

.internal .header-logo img {
  width: 220px;
}
@media (min-width: 480px) {
  .internal .header-logo img {
    width: 250px;
  }
}
.internal .main-menu-button p {
  margin: 22px 0;
}
@media (min-width: 960px) {
  .internal .first-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    width: auto;
    margin: auto;
    padding: 8px 8px 6px 8px;
  }
}
.internal main .column, .internal main .columns, .internal main .newslist .latestnews-item, .newslist .internal main .latestnews-item {
  min-height: 100%;
  margin: 0;
  padding: 50px 10px;
}
@media (min-width: 960px) {
  .internal main .column, .internal main .columns, .internal main .newslist .latestnews-item, .newslist .internal main .latestnews-item {
    padding: 120px 40px 100px;
  }
}
@media (min-width: 1600px) {
  .internal main .column, .internal main .columns, .internal main .newslist .latestnews-item, .newslist .internal main .latestnews-item {
    padding: 120px 100px 100px;
  }
}
.internal main .column.full-height-column, .internal main .full-height-column.columns, .internal main .newslist .full-height-column.latestnews-item, .newslist .internal main .full-height-column.latestnews-item {
  padding: 0 50px;
}
@media (min-width: 960px) {
  .internal main .column.full-height-column, .internal main .full-height-column.columns, .internal main .newslist .full-height-column.latestnews-item, .newslist .internal main .full-height-column.latestnews-item {
    padding: 0 100px;
  }
}
.internal main .column.below-header-inner, .internal main .below-header-inner.columns, .internal main .newslist .below-header-inner.latestnews-item, .newslist .internal main .below-header-inner.latestnews-item {
  padding: 50px;
}
@media (min-width: 960px) {
  .internal main .column.below-header-inner, .internal main .below-header-inner.columns, .internal main .newslist .below-header-inner.latestnews-item, .newslist .internal main .below-header-inner.latestnews-item {
    padding: 100px;
  }
}
@media (max-width: 1199px) {
  .internal main .one-half,
  .internal main .full-one-half {
    width: 100%;
  }
}
.internal .menu-link button {
  margin: 25px 20px 10px 20px;
}
.internal .social-icons {
  display: none;
}

.page-slider {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
}
@media (min-width: 1600px) {
  .page-slider {
    display: block;
  }
}
.page-slider .cycle-slide {
  width: 100%;
  z-index: 1 !important;
  margin: 0;
}

.page-slider-column {
  margin-left: 0 !important;
}
@media (min-width: 1600px) {
  .page-slider-column {
    margin-left: 50% !important;
  }
}

/* Clients
================================================== */
.full-client-list {
  width: 100%;
  float: left;
  padding: 50px;
  background: #111b58;
  background: -moz-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #111b58), color-stop(100%, #004990));
  background: -webkit-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -o-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -ms-linear-gradient(top, #111b58 0%, #004990 100%);
  background: linear-gradient(to bottom, #111b58 0%, #004990 100%);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr="#111b58", endColorstr="#004990");
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#111b58', endColorstr='#004990')";
}
@media (min-width: 960px) {
  .full-client-list {
    padding: 100px;
  }
}
.full-client-list ul {
  margin: 0;
}
.full-client-list li {
  position: relative;
  width: 100%;
  height: 200px;
  float: left;
  padding: 30px;
  list-style: none;
  text-align: center;
}
@media (min-width: 480px) {
  .full-client-list li {
    width: 50%;
  }
}
@media (min-width: 960px) {
  .full-client-list li {
    width: 33.3%;
    height: 250px;
  }
}
@media (min-width: 1600px) {
  .full-client-list li {
    width: 25%;
    height: 300px;
  }
}
@media (min-width: 1600px) {
  .full-client-list li {
    width: 20%;
  }
}
.full-client-list li a {
  text-decoration: none;
}
.full-client-list span {
  display: block;
  text-transform: uppercase;
  font-size: 1.8em;
  font-weight: bold;
}
.full-client-list img {
  max-width: 100%;
}

/* Accordion
================================================== */
.accordion-toggle {
  cursor: pointer;
  -webkit-transition: color 400ms ease-in-out;
  -moz-transition: color 400ms ease-in-out;
  -ms-transition: color 400ms ease-in-out;
  -o-transition: color 400ms ease-in-out;
  transition: color 400ms ease-in-out;
}
.accordion-toggle:hover {
  color: #c9a66f;
}

/* News
================================================== */
.aidanews2 .aidanews2_art {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.aidanews2 .aidanews2_positions > div {
  margin-bottom: 5px;
}
.aidanews2 .even {
  background-color: transparent !important;
}
.aidanews2 .aidanews2_line {
  display: none;
}
.aidanews2 .aidanews2_mainC {
  overflow: visible;
}

main .paginationstyle a,
main .items_pagination a {
  margin: 0 3px;
  padding: 3px 6px;
  border: none;
  color: #ffffff;
  background-color: #c9a66f;
  text-decoration: none;
}
main .paginationstyle a:hover,
main .paginationstyle li.active a,
main .items_pagination a:hover,
main .items_pagination li.active a {
  background-color: #c29b5c;
}
main .sharethis {
  margin: 40px 0 20px;
}
main .stButton .stFb,
main .stButton .stTwbutton,
main .stButton .stMainServices {
  height: auto;
}

.newslist li {
  margin-bottom: 30px !important;
}
.newslist .newstitle {
  margin-bottom: 10px !important;
}
.newslist .newstitle a {
  font-weight: bold;
  text-decoration: none;
}
.newslist .newstitle span {
  font-family: "Montserrat", sans-serif;
}
.newslist .newsintro {
  line-height: 160%;
  margin: 0 0 10px 0;
}

main .latestnews-item .newshead {
  width: 100%;
}
main .latestnews-item .newshead .calendar {
  position: static;
  width: 100% !important;
  max-width: none !important;
  background: transparent;
  border: none;
  font-family: "Montserrat", sans-serif !important;
  text-align: left !important;
}
main .latestnews-item .newshead .calendar span,
main .latestnews-item .newshead .calendar .position1 {
  margin: 0 2px 0 0 !important;
  font-size: 0.9em !important;
  letter-spacing: 0 !important;
  text-indent: 0 !important;
  line-height: 100% !important;
  font-weight: 200 !important;
}
main .items_pagination .page_link.active_page {
  text-decoration: none !important;
  font-size: 1.3em;
  font-weight: bold;
}

.article-info-term {
  display: none;
}

.page-header .highlight {
  font-family: "Montserrat", sans-serif;
}

/* Intro Section
================================================== */
.first-section {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: #111b58;
  overflow: hidden;
}
.first-section.intro {
  padding: 20px 20px 200px 20px;
  background: #111b58;
  background: -moz-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #111b58), color-stop(100%, #004990));
  background: -webkit-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -o-linear-gradient(top, #111b58 0%, #004990 100%);
  background: -ms-linear-gradient(top, #111b58 0%, #004990 100%);
  background: linear-gradient(to bottom, #111b58 0%, #004990 100%);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr="#111b58", endColorstr="#004990");
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#111b58', endColorstr='#004990')";
}
@media (min-width: 1600px) {
  .first-section.intro {
    height: 1200px;
  }
}

.intro-section {
  position: relative;
  z-index: 9;
  width: 100%;
  float: left;
  margin-top: 50px;
}
@media (min-width: 960px) {
  .intro-section {
    float: none;
    margin-top: 0;
  }
}
@media (min-width: 1600px) {
  .intro-section .container {
    width: 1600px;
    max-width: 1600px;
  }
}

.intro-text {
  width: 100%;
  margin-bottom: 40px;
  padding-left: 0;
}
@media (min-width: 480px) {
  .intro-text {
    width: 75%;
    padding-left: 10px;
  }
}
.intro-text .title {
  font-size: 1.6em;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #c9a66f;
  text-transform: uppercase;
  line-height: 130%;
}
@media (min-width: 960px) {
  .intro-text .title {
    font-size: 2.4em;
  }
}
@media (min-width: 960px) {
  .intro-text .title {
    font-size: 3em;
  }
}
@media (min-width: 1600px) {
  .intro-text .title {
    font-size: 3.6em;
  }
}
@media (min-width: 1600px) {
  .intro-text .title {
    font-size: 4.6em;
  }
}

/* Mouse Scroll
================================================== */
.mouse-scroll {
  position: absolute;
  right: 0;
  bottom: 25px;
  left: 0;
  display: none;
  margin: auto;
  text-align: center;
}
@media (min-width: 960px) {
  .mouse-scroll {
    display: block;
  }
}
.mouse-scroll .container:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;
  left: 0;
  z-index: 1;
  margin: auto;
  height: 400px;
  background: url("${template-image-path}/mouse-shadow.png") no-repeat 50% 50%;
  visibility: visible;
}
@media (min-width: 960px) {
  .mouse-scroll .container:after {
    display: block;
  }
}
.mouse-scroll .mouse-icon {
  position: relative;
}
.mouse-scroll .mouse-icon .mouse-icon-arrow {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-animation: mouseScroll 0.6s alternate infinite;
  animation: mouseScroll 0.6s alternate infinite;
}

@-webkit-keyframes mouseScroll {
  0% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}
@keyframes mouseScroll {
  0% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
  100% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
}
/* Team Members
================================================== */
.internal main .column.team-page, .internal main .team-page.columns, .internal main .newslist .team-page.latestnews-item, .newslist .internal main .team-page.latestnews-item {
  padding: 50px 10px 0;
}
@media (min-width: 960px) {
  .internal main .column.team-page, .internal main .team-page.columns, .internal main .newslist .team-page.latestnews-item, .newslist .internal main .team-page.latestnews-item {
    padding: 120px 40px 0;
  }
}
@media (min-width: 1600px) {
  .internal main .column.team-page, .internal main .team-page.columns, .internal main .newslist .team-page.latestnews-item, .newslist .internal main .team-page.latestnews-item {
    padding: 120px 100px 0;
  }
}

h2.position {
  color: #ffffff;
}
h2.position span {
  color: #eeeeee;
  font-size: 0.8em;
}

.team-members {
  width: 100%;
  float: left;
  margin-bottom: 80px;
}
.team-members ul {
  margin: 0;
}
.team-members li {
  width: 50%;
  float: left;
  padding: 20px;
  list-style: none;
  text-align: center;
}
@media (min-width: 960px) {
  .team-members li {
    width: 25%;
  }
}
@media (min-width: 1600px) {
  .team-members li {
    width: 14.25%;
  }
}
.team-members li:hover .team-info {
  background-color: rgb(121, 131, 141);
}
.team-members a {
  position: relative;
  width: 100%;
  float: left;
  height: 100%;
  text-decoration: none !important;
}
.team-members p {
  color: #ffffff;
}
.team-members p.title {
  font-size: 0.9em;
  color: #d4bb80 !important;
}
.team-members p.position {
  margin-bottom: 20px;
  font-size: 1.1em;
  text-transform: uppercase;
}
.team-members p.sub-title {
  font-size: 0.8em;
  color: #bec6d7 !important;
  text-transform: uppercase;
}
.team-members .team-image {
  width: 100%;
  height: 220px;
  float: left;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.team-members .team-info {
  -webkit-transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -ms-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  padding: 20px 10px 10px;
  background-color: rgb(121, 131, 141);
  background-color: rgba(121, 131, 141, 0.6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#79838d, endColorstr=#79838d);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#79838d, endColorstr=#79838d)";
}

.patents-team-text {
  margin-top: 40px;
}

.membersWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membersWrap .membersWrap--image {
  width: 400px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 959px) {
  .membersWrap .membersWrap--image {
    width: 100%;
  }
}
.membersWrap .membersWrap--container {
  width: calc(100% - 450px);
}
@media (max-width: 959px) {
  .membersWrap .membersWrap--container {
    width: 100%;
  }
}

/* Team Members
================================================== */
.internal main .column.team-page, .internal main .team-page.columns, .internal main .newslist .team-page.latestnews-item, .newslist .internal main .team-page.latestnews-item {
  padding: 50px 10px 0;
}
@media (min-width: 960px) {
  .internal main .column.team-page, .internal main .team-page.columns, .internal main .newslist .team-page.latestnews-item, .newslist .internal main .team-page.latestnews-item {
    padding: 120px 40px 0;
  }
}
@media (min-width: 1600px) {
  .internal main .column.team-page, .internal main .team-page.columns, .internal main .newslist .team-page.latestnews-item, .newslist .internal main .team-page.latestnews-item {
    padding: 120px 100px 0;
  }
}

h2.position {
  color: #ffffff;
}
h2.position span {
  color: #eeeeee;
  font-size: 0.8em;
}

.team-members {
  width: 100%;
  float: left;
  margin-bottom: 80px;
}
.team-members ul {
  margin: 0;
}
.team-members li {
  width: 50%;
  float: left;
  padding: 20px;
  list-style: none;
  text-align: center;
}
@media (min-width: 960px) {
  .team-members li {
    width: 25%;
  }
}
@media (min-width: 1600px) {
  .team-members li {
    width: 14.25%;
  }
}
.team-members li:hover .team-info {
  background-color: rgb(121, 131, 141);
}
.team-members a {
  position: relative;
  width: 100%;
  float: left;
  height: 100%;
  text-decoration: none !important;
}
.team-members p {
  color: #ffffff;
}
.team-members p.title {
  font-size: 0.9em;
  color: #d4bb80 !important;
}
.team-members p.position {
  margin-bottom: 20px;
  font-size: 1.1em;
  text-transform: uppercase;
}
.team-members p.sub-title {
  font-size: 0.8em;
  color: #bec6d7 !important;
  text-transform: uppercase;
}
.team-members .team-image {
  width: 100%;
  height: 220px;
  float: left;
  margin-bottom: 15px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.team-members .team-info {
  -webkit-transition: background-color 0.25s ease-in-out;
  -moz-transition: background-color 0.25s ease-in-out;
  -ms-transition: background-color 0.25s ease-in-out;
  -o-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  padding: 20px 10px 10px;
  background-color: rgb(121, 131, 141);
  background-color: rgba(121, 131, 141, 0.6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#79838d, endColorstr=#79838d);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#79838d, endColorstr=#79838d)";
}

.patents-team-text {
  margin-top: 40px;
}

.membersWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.membersWrap .membersWrap--image {
  width: 400px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 959px) {
  .membersWrap .membersWrap--image {
    width: 100%;
  }
}
.membersWrap .membersWrap--container {
  width: calc(100% - 450px);
}
@media (max-width: 959px) {
  .membersWrap .membersWrap--container {
    width: 100%;
  }
}

/* Fonts
================================================== */
@font-face {
  font-family: "montserratultra_light";
  src: url("../fonts/montserrat-ultralight-webfont.eot");
  src: url("../fonts/montserrat-ultralight-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-ultralight-webfont.woff") format("woff"), url("../fonts/montserrat-ultralight-webfont.ttf") format("truetype"), url("../fonts/montserrat-ultralight-webfont.svg#montserratultra_light") format("svg");
  font-weight: normal;
  font-style: normal;
}