/* Header
================================================== */
header {
	position: relative;
	z-index: 10;
	width: 100%;
	float: left;
	/*margin-bottom: 30px;
    @include bp(smedium) {
    	margin-bottom: 50px;
    }
    @include bp(large) {
    	margin-bottom: 80px;
    }
    @include bp(xxlarge) {
    	margin-bottom: 120px;
    }*/
}

.header-logo {
	position: relative;
	img {
		width: 370px;
		@include bp_max(large) {
			width: 100%;
		}
	}
	a {
		display: block;
	}
	&.patents-logo {
		img {
			width: 312px;
		}
	}
}

.header-left {
	float: left;
	@include bp(smedium) {
		margin-right: 10px;
	}
}
.header-right {
	float: right;
	@include bp(smedium) {
		margin-left: 10px;
	}
}

.intro {
	@include bp(large) {
		.header-logo {
			&:after {
				content: "";
				position: absolute;
				top: 70px;
				left: -10px;
				z-index: -1;
				width: 500px;
				height: 400px;
				background: url("#{template-image-path}/logo-shadow.png") no-repeat 0 0;
				@include bp(smedium) {
					top: 80px;
					left: 0;
				}
			}
		}
	}
	.social-icons {
		width: 80px;
		float: right;
		display: none;
		clear: right;
		@include bp(large) {
			display: block;
		}
		a {
			display: block;
			padding: 10px;
			text-align: center;
			i {
				font-size: 1.4em;
				color: $white;
				@include transition(color 400ms ease-in-out);
				&.fa-google-plus {
					font-size: 1.2em;
				}
			}
			&:hover {
				i {
					color: $light-blue;
				}
			}
		}
	}
}
